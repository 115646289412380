<template>
    <div>
        <div v-if="authUserPermission['scope-of-supply-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--7">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-3">
                                        <h3 class="mb-0">{{ tt('up_sos') }}</h3>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_sos')" v-on:keyup="filter"/> 
                                    </div>
                                    <div class="col-3 text-right">
                                        <!-- <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button> -->
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="400px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" ref="singleTable" highlight-current-row @current-change="handleCurrentChange" v-if="!onLoad.sos">
                                    <el-table-column :label="tt('sos_header')" prop="sos_header" min-width="100px" sortable></el-table-column>
                                    <el-table-column :label="tt('sos_description')" prop="sos_description" min-width="150px" sortable></el-table-column>
                                    <!-- <el-table-column prop="action" width="100">
                                        <template v-slot="{row}">
                                            <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['scope-of-supply-update'] || authUserPermission['scope-of-supply-delete']">
                                                <span class="btn btn-sm btn-icon-only text-light">
                                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                                </span>
                                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                    <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['scope-of-supply-update']">{{ tt('edit') }}</el-dropdown-item>
                                                    <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['scope-of-supply-delete']">{{ tt('delete') }}</el-dropdown-item>                                        
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column> -->
                                </el-table>
                                <page-loading v-else/>
                            </div>
                            <div class="card-footer pb-0 ">   
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>                             
                                <span class="float-right">
                                    {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="mb-0">{{ tt('material_group') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="400px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedSos" ref="singleTable" v-if="!onLoad.detailMGroup">
                                    <el-table-column :label="tt('code')" prop="material_group_code" min-width="100px" sortable></el-table-column>
                                    <el-table-column :label="tt('description')" prop="description" min-width="150px" sortable></el-table-column>
                                </el-table>
                                <page-loading v-else/>
                            </div>
                            <div class="card-footer">                         
                                <div class="col text-right">
                                    {{ tt('page_info', {size: table.total, total: table.total}) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>
                        
                        <label class="form-control-label">{{ tt('sos_header') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('sos_header')" :placeholder="tt('sos_header')" v-model="scopeofSupply.sos_header" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('sos_description') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('sos_description')" :placeholder="tt('sos_description')" v-model="scopeofSupply.sos_description" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('material_group') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('material_group')" rules="required">
                            <el-select v-model="scopeofSupply.material_group" multiple placeholder="Select Material Group">
                                <el-option
                                  v-for="item in materialGroup"
                                  :key="item.id"
                                  :label="item.code"
                                  :value="item.code">
                                </el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.showTableing || invalid">
                            <span v-if="btnSave.showTableing"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import scopeofSupply from '@/services/dictionary/scopeofSupply.service';
    import materialGroup from '@/services/master/materialGroup.service';

    export default {        
        data() {
            return {   
                currentRow: null,
                onLoad: {
                    sos: true,
                    detailMGroup: true,
                },
                loadTimeout: null,
                btnSave: {
                    showTableing: false
                },    
                form: {
                    add: true,
                    title: null,
                    show: false,
                },
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: [],
                },
                errorMessage: null,   
                scopeofSupply: {},
                materialGroup: {},
                selectedSos: [],
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getMaterialGroup()
        },
        methods: {
            get() {
                let context = this;

                Api(context, scopeofSupply.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;
                    context.onLoad.sos = false;
                    context.onLoad.detailMGroup = false;
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad.sos = false;
                        context.onLoad.detailMGroup = false;
                    }
                }).call()
            },
            getMaterialGroup() {
                let context = this;

                Api(context, materialGroup.get({per_page: 'none'})).onSuccess(function(response) {
                    context.materialGroup = response.data.data.data.data;
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.materialGroup = [];
                    }
                }).call()
            },
            filter() {
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.onLoad.sos = true;
                    this.get()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_scope_of_supply');
                this.form.show = true;
                this.scopeofSupply = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, scopeofSupply.show(id)).onSuccess(function(response) {
                    context.scopeofSupply = response.data.data
                    var material_group = [];
                    response.data.data.rel_sos_material_group.forEach(function(item) {
                        material_group.push(item.material_group_code)
                    })

                    context.scopeofSupply.material_group = material_group

                    context.form.add = false;
                    context.form.title = context.tt('edit_scope_of_supply');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                  
                this.btnSave.showTableing = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, scopeofSupply.create(this.scopeofSupply));
                } else {{
                    api = Api(context, scopeofSupply.update(this.scopeofSupply.id, this.scopeofSupply));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    if (!context.form.add) {
                        context.handleCurrentChange(context.currentRow)
                    }
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.showTableing = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, scopeofSupply.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                let context = this; 
                context.currentRow = val     
                this.onLoad.detailMGroup = true

                Api(context, scopeofSupply.relSosMGroup(val.sos_header)).onSuccess(function(response) {
                    context.selectedSos = response.data.data.data.data
                }).onError(function(error) {                    
                    context.selectedSos = []
                }).onFinish(function() {
                    context.onLoad.detailMGroup = false
                })
                .call()
            },
            rowClassName({ rowIndex }) {
                return 'table-success'
            },
            changePage(page) {
                let context = this;
                context.onLoad.sos = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()

                    context.selectedSos = []
                }, 100);
            }
        }   
    };
</script>
<style></style>
    